exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-book-consult-page-js": () => import("./../../../src/templates/bookConsultPage.js" /* webpackChunkName: "component---src-templates-book-consult-page-js" */),
  "component---src-templates-book-demo-page-js": () => import("./../../../src/templates/bookDemoPage.js" /* webpackChunkName: "component---src-templates-book-demo-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/homePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-legal-page-js": () => import("./../../../src/templates/legalPage.js" /* webpackChunkName: "component---src-templates-legal-page-js" */),
  "component---src-templates-marketing-page-js": () => import("./../../../src/templates/marketingPage.js" /* webpackChunkName: "component---src-templates-marketing-page-js" */),
  "component---src-templates-pricing-page-js": () => import("./../../../src/templates/pricingPage.js" /* webpackChunkName: "component---src-templates-pricing-page-js" */)
}

