const defaultLocale = "en-US";
const supportedLocales = [defaultLocale, "en-CA"];
const Cookies = require("js-cookie");

const buildPath = (locale, path) =>
  locale === defaultLocale ? path : `/${locale.toLowerCase()}${path}`;

const switchLocale = (urlPath, desiredLocale) => {
  let path = urlPath;
  for (const locale of supportedLocales) {
    if (path.startsWith(`/${locale.toLowerCase()}`)) {
      path = path.substring(locale.length + 1);
      break;
    }
  }
  return buildPath(desiredLocale, path);
};

const setLocaleCookie = (locale, expiry = 30) => {
  Cookies.set("haloo-locale", locale, {
    expires: expiry, // expires after 30 days
  });
};

const getLocaleCookie = () => {
  return Cookies.get("haloo-locale") || null;
};

module.exports = {
  buildPath,
  switchLocale,
  getLocaleCookie,
  setLocaleCookie,
};
