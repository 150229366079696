/* eslint-disable */
import React, { useEffect } from "react";
import { QueryParamProvider } from "use-query-params";
import { Location, globalHistory } from "@reach/router";
import { setLocaleCookie, getLocaleCookie, switchLocale } from "@/utils";
import { navigate } from "gatsby-link";
export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

const initialState = {
  locale: "en-US",
  location: "/",
  loading: true,
  showModal: false,
  searchLocale: "en-US",
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_LOCALE": {
      return {
        ...state,
        locale: action.payload.locale,
      };
    }
    case "SET_LOADING": {
      return {
        ...state,
        loading: action.payload.loading,
      };
    }
    case "SET_SHOW_MODAL": {
      return {
        ...state,
        showModal: action.payload,
      };
    }
    case "SET_LOCATION": {
      return {
        ...state,
        location: action.payload.location,
        search: action.payload.search,
      };
    }
    default:
      throw new Error("invalid action type");
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const getLocale = async () => {
    let locale = await getLocaleCookie();

    // if there is no locale set in the cookie then display locale modal to set it
    if (!locale) {
      dispatch({ type: "SET_SHOW_MODAL", payload: true });
    } else {
      const checkUrlLocale = window.location.pathname.includes(
        locale.toLowerCase()
      );
      // if the user's cookie is set to en-ca and they try to land on a us page OR if the cookie is set to en-us and they land on a canadian page navigate
      if (checkUrlLocale === false && locale.toLowerCase() !== "en-us") {
        // eslint-disable-next-line no-restricted-globals
        const newPath = switchLocale(
          `${location.pathname + location.search}`,
          locale
        );
        navigate(newPath);
      }

      dispatch({ type: "SET_LOCALE", payload: { locale: locale } });
    }

    dispatch({ type: "SET_LOADING", payload: { loading: false } });
  };

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals

    getLocale();
    window.dataLayer = window.dataLayer || [];
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // each time the locale changes, set it in cookies
  useEffect(() => {
    setLocaleCookie(state.locale);
  }, [state.locale]);

  return (
    <Location>
      {({ location }) => (
        <QueryParamProvider location={location} reachHistory={globalHistory}>
          <GlobalStateContext.Provider value={state}>
            <GlobalDispatchContext.Provider value={dispatch}>
              {children}
            </GlobalDispatchContext.Provider>
          </GlobalStateContext.Provider>
        </QueryParamProvider>
      )}
    </Location>
  );
};
export default GlobalContextProvider;
